'use client';

import { mdiArrowLeftThick } from '@mdi/js';
import TextButton from '../Button/TextButton';
import Logo from '../Logo/Logo';
import { useRouter } from 'next/navigation';
import SkipToContent from './SkipToContent';
import { twMerge } from 'tailwind-merge';
import { measure } from '../Analytics/Analytics';

const Header = () => {
    const router = useRouter();

    return (
        <nav className="relative shrink-0 w-full mt-4">
            <div className="absolute start-4 md:start-12 top-1/2 max-md:pt-0.5 -translate-y-1/2">
                <HeaderButton
                    label="home"
                    icon={mdiArrowLeftThick}
                    className="flex-row-reverse ps-2 md:ps-2 pe-2 md:pe-3"
                    onClick={() => {
                        measure('home_button', 'navigation');
                        router.push('/?from=button');
                    }}
                />
                <SkipToContent />
            </div>
            <a
                href="/?from=logo"
                aria-label="Home"
                title="Home"
                className="shrink-0 block mx-14"
                tabIndex={-1}
                onClick={() => measure('home_logo', 'navigation')}
            >
                <Logo className="h-10 md:h-12 lg:h-16 max-w-full mx-auto" />
            </a>
            <div
                id="header-portal"
                className="absolute end-4 md:end-12 top-1/2 max-md:pt-0.5 -translate-y-1/2"
            />
        </nav>
    );
};

export const HeaderButton = ({
    label,
    icon,
    isPrimary = false,
    className,
    onClick,
}: {
    label: string;
    icon: string;
    isPrimary?: boolean;
    className?: string;
    onClick: () => void;
}) => (
    <TextButton
        className={twMerge(
            'gap-0 md:gap-1.5 ps-2 md:ps-3 pe-2 md:pe-2 py-2 md:py-1 inner-border-transparent md:inner-border-white/25 text-white',
            className,
        )}
        icon={icon}
        isSmall
        isPrimary={isPrimary}
        onClick={onClick}
    >
        <span className="hidden md:block">{label}</span>
    </TextButton>
);

export default Header;
