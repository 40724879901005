import { mdiSkipForward } from '@mdi/js';
import TextButton from '../Button/TextButton';

const SkipToContent = () => {
    return (
        <div className="absolute top-4 inset-x-4">
            <TextButton
                className="sr-only focus:not-sr-only rounded-md"
                icon={mdiSkipForward}
                onClick={() => {
                    // Skip to the <main> element
                    const main = document.querySelector('main');
                    if (main) {
                        main.tabIndex = 0;
                        main.focus();
                        const removeBlur = () => {
                            main.tabIndex = -1;
                            main.removeEventListener('blur', removeBlur);
                        };
                        // Remove tabIndex on blur
                        main.addEventListener('blur', removeBlur);
                    }
                }}
                iconSize={2}
            >
                Gelijk naar de inhoud
            </TextButton>
        </div>
    );
};

export default SkipToContent;
